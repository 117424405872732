<template>
  <div>
    <header-slot>
      <template v-if="$route.meta.isActive === 1" #actions>
          <b-row class="justify-content-end mr-1">
              <b-button v-if="currentUser.user_id === 1" variant="outline-danger" :to="'/ti/users/session/0'">
                <tabler-icon icon="HistoryIcon"/>
                History Access
              </b-button>
              <b-button variant="success" :to="'/ti/users/create'" class="ml-1">
                <feather-icon icon="PlusIcon"/>
                  Create
              </b-button>
          </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'paragon-users-active' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >Active</b-nav-item>
      <b-nav-item
        :to="{ name: 'paragon-users-inactive' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >Inactive</b-nav-item>
    </b-nav>

    <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser"
        }),
    }
}
</script>